import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import GlobalWrapper from '../../Components/GlobalWrapper';

import { getPrivacyPolicy } from '../../Contentful';

const PrivacyPolicy = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState(null);

  const { i18n } = useTranslation();

  const selectedLanguage = i18n.language;

  const portugueseSelected = selectedLanguage === 'pt-PT';

  useEffect(() => {
    const _getPrivacyData = async () => {
      const data = await getPrivacyPolicy();

      setPrivacyPolicy(data);
    };

    _getPrivacyData();
  }, []);

  if (!privacyPolicy) return '';

  return (
    <GlobalWrapper className="my-10">
      {portugueseSelected && parse(privacyPolicy?.descriptionPT || '')}
      {!portugueseSelected && parse(privacyPolicy?.descriptionEN || '')}
    </GlobalWrapper>
  );
};

export default PrivacyPolicy;
