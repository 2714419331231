import { Trans, useTranslation } from 'react-i18next';

function Translator({ path, plainText = false, variables = {} }) {
  const { t } = useTranslation();

  if (plainText) return t(path, variables);

  return (
    <Trans t={t} values={variables}>
      {path}
    </Trans>
  );
}

export default Translator;
