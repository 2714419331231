import { createClient } from 'contentful';
import { defaultTo, head, pipe, prop, propOr, sortBy } from 'ramda';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

import { parseImage } from './utils/parseImage';

const exist = Boolean;

const getItems = propOr([], 'items');
const getFields = propOr({}, 'fields');
const defaultToObj = defaultTo({});

const getHomepageFields = pipe(getItems, head, getFields, defaultToObj);

const sortByRank = sortBy(prop('rank'));

/**
 * create Contentful Client
 */
export const client = createClient({
  accessToken: process.env.REACT_APP_CONTENTFUL_API_KEY,
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
});

/**
 * Retrieve the Homepage data from Contentful
 *
 * @param {string} slug
 * @returns
 */
export const getHomepageData = async () => {
  const response = await client.getEntries({
    content_type: 'homepage',
  });

  const homepageData = getHomepageFields(response);

  homepageData.banner = (homepageData.banner || []).map((banner) =>
    parseImage(banner)
  );

  return homepageData;
};

/**
 * Retrieve the Races data from Contentful
 *
 * @returns
 */
export const getRacesData = async () => {
  const response = await client.getEntries({
    content_type: 'races',
  });

  const responseItems = getItems(response);

  const racesInfo = responseItems.map((item) => ({
    ...item.fields,
    raceBanner: exist(item.fields.raceBanner)
      ? parseImage(item.fields.raceBanner)
      : null,
    raceHighlight: exist(item.fields.raceHighlight)
      ? parseImage(item.fields.raceHighlight)
      : null,
    raceDescriptionEN: exist(item.fields.raceDescriptionEN)
      ? documentToHtmlString(item.fields.raceDescriptionEN)
      : null,
    raceDescriptionPT: exist(item.fields.raceDescriptionPT)
      ? documentToHtmlString(item.fields.raceDescriptionPT)
      : null,
  }));

  return sortByRank(racesInfo);
};

/**
 * Retrieve the Regulamento data from Contentful
 *
 * @returns
 */
export const getRegulamento = async () => {
  const response = await client.getEntries({
    content_type: 'regulamento',
  });

  const responseItems = getItems(response);

  const regulamento = responseItems.map((item) => ({
    ...item.fields,
  }));

  return head(regulamento);
};

export const getRaceDataById = async (raceId) => {
  if (!raceId) return;

  const racesData = await getRacesData();

  const raceInfo = racesData.find((race) => race.raceId === raceId);

  return raceInfo;
};

export const getCookiesPolicy = async () => {
  const response = await client.getEntries({
    content_type: 'policies',
  });

  const responseItems = getItems(response);

  const cookiesPolicyItem = responseItems.find(
    (item) => item?.fields?.policyTitle === 'Cookies Policy'
  );

  if (!cookiesPolicyItem) return;

  const cookiesPolicy = {
    ...cookiesPolicyItem.fields,
    descriptionPT: exist(cookiesPolicyItem?.fields?.descriptionPT)
      ? documentToHtmlString(cookiesPolicyItem.fields.descriptionPT)
      : null,
    descriptionEN: exist(cookiesPolicyItem?.fields?.descriptionEN)
      ? documentToHtmlString(cookiesPolicyItem.fields.descriptionEN)
      : null,
  };

  return cookiesPolicy;
};

export const getPrivacyPolicy = async () => {
  const response = await client.getEntries({
    content_type: 'policies',
  });

  const responseItems = getItems(response);

  const privacyPolicyItem = responseItems.find(
    (item) => item?.fields?.policyTitle === 'Privacy Policy'
  );

  if (!privacyPolicyItem) return;

  const privacyPolicy = {
    ...privacyPolicyItem.fields,
    descriptionPT: exist(privacyPolicyItem?.fields?.descriptionPT)
      ? documentToHtmlString(privacyPolicyItem.fields.descriptionPT)
      : null,
    descriptionEN: exist(privacyPolicyItem?.fields?.descriptionEN)
      ? documentToHtmlString(privacyPolicyItem.fields.descriptionEN)
      : null,
  };

  return privacyPolicy;
};
