// Por último, importamos tudo e exportamos um único objeto

import ptPtranslations from './pt-pt';
import enUsTranslations from './en-us';

const localizations = {
  'pt-PT': ptPtranslations, // Atente-se a usar as abreviaturas corretas
  'en-US': enUsTranslations, // https://support.mozilla.org/pt-BR/kb/abreviacao-de-localizacao
};

export default localizations;
