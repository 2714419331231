import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { getRaceDataById } from '../../Contentful';
import GlobalWrapper from '../../Components/GlobalWrapper';

import './style.css';

const Component = () => {
  const { raceId } = useParams();

  const { i18n } = useTranslation();

  const selectedLanguage = i18n.language;

  const portugueseSelected = selectedLanguage === 'pt-PT';

  const [raceData, setRaceData] = useState();

  useEffect(() => {
    const _getRaceData = async () => {
      const data = await getRaceDataById(raceId);

      setRaceData(data);
    };

    _getRaceData();
  }, [raceId]);

  if (!raceData) return '';

  return (
    <>
      <GlobalWrapper fullScreen>
        <img
          src={raceData?.raceBanner?.src}
          alt={raceData?.raceBanner?.alt}
          className="w-full"
        />
      </GlobalWrapper>

      <GlobalWrapper className="py-10">
        <h1 className="text-4xl pb-14">{raceData?.raceTitle}</h1>
        <div className={classNames('')}>
          {portugueseSelected &&
            raceData?.raceDescriptionPT &&
            parse(raceData?.raceDescriptionPT)}
          {!portugueseSelected &&
            raceData?.raceDescriptionEN &&
            parse(raceData?.raceDescriptionEN)}
        </div>
      </GlobalWrapper>
    </>
  );
};

export default Component;
