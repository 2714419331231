import classNames from 'classnames';
import { Link } from 'react-router-dom';

import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';

import GlobalWrapper from '../GlobalWrapper';
import Translator from '../../i18n/Translator';

const FooterComponent = () => {
  return (
    <GlobalWrapper fullScreen className={classNames('bg-[rgb(44,44,44)]')}>
      <GlobalWrapper className={classNames('py-10')}>
        <div
          className={classNames(
            'text-white text-xl font-light',
            'flex justify-between'
          )}
        >
          <div className={classNames('flex flex-col')}>
            <span>
              <Translator path="contacts" />
            </span>
            <span className="text-base">
              <a href="mailto:geral@axtrailseries.pt">geral@axtrailseries.pt</a>
            </span>
          </div>
          <div className={classNames('flex flex-col')}>
            <span>
              <Translator path="legalPages" />
            </span>
            <span className="text-base">
              <Link to="privacy-policy">
                <Translator path="privacyPolicy" />
              </Link>
            </span>
            <span className="text-base">
              <Link to="cookies-policy">
                <Translator path="cookiesPolicy" />
              </Link>
            </span>
          </div>
        </div>
      </GlobalWrapper>
      <GlobalWrapper className={classNames('py-10')}>
        <div
          className={classNames(
            'text-white text-xl font-light',
            'flex justify-center gap-4'
          )}
        >
          <span>AXtrail®</span>|<span>És tu e o trilho</span>|
          <span>
            <a
              className="mr-2"
              href="https://www.facebook.com/AXtrailseries"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookRoundedIcon />
            </a>{' '}
            <a
              href="https://www.instagram.com/axtrail/"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon />
            </a>
          </span>
        </div>
      </GlobalWrapper>
    </GlobalWrapper>
  );
};

export default FooterComponent;
