import classNames from 'classnames';
import Translator from '../../i18n/Translator';

const CookiesPopoverComponent = ({ handleCookieAccepted }) => {
  return (
    <div
      className={classNames(
        'fixed bottom-0 flex w-full p-5',
        'bg-[rgb(44,44,44)] text-white',
        'items-center justify-around'
      )}
    >
      <div>
        <h3 className={classNames('text-lg')}>
          <Translator path="cookies.bar.title" />
        </h3>
        <p>
          <Translator path="cookies.bar.details" />
        </p>
      </div>
      <div>
        <button
          className={classNames(
            'px-5 py-2 rounded-md',
            'text-[rgb(44,44,44)] bg-white'
          )}
          onClick={handleCookieAccepted}
        >
          <Translator path="cookies.bar.acceptButton" />
        </button>
      </div>
    </div>
  );
};

export default CookiesPopoverComponent;
