import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import GlobalWrapper from '../../Components/GlobalWrapper';
import { getCookiesPolicy } from '../../Contentful';

const CookiesPolicy = () => {
  const [cookiesPolicy, setCookiesPolicy] = useState(null);

  const { i18n } = useTranslation();

  const selectedLanguage = i18n.language;

  const portugueseSelected = selectedLanguage === 'pt-PT';

  useEffect(() => {
    const _getCookiesData = async () => {
      const data = await getCookiesPolicy();

      setCookiesPolicy(data);
    };

    _getCookiesData();
  }, []);

  if (!cookiesPolicy) return '';

  return (
    <GlobalWrapper className="my-10">
      {portugueseSelected && parse(cookiesPolicy?.descriptionPT || '')}
      {!portugueseSelected && parse(cookiesPolicy?.descriptionEN || '')}
    </GlobalWrapper>
  );
};

export default CookiesPolicy;
