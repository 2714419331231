const translationData = {
  translations: {
    cookies: {
      bar: {
        title: 'Cookies',
        details:
          'This website uses cookies and other tracking technologies to aid your browsing and our ability to provide feedback, analyze the use of our website and help provide promotional information about our services and products.',
        acceptButton: 'Accept',
      },
      page: {
        pageTitle: 'Cookies Policy',
        section1: {
          title: 'What are cookies?',
          p1: "Cookies are small text files that a website places on the user's computer or mobile device when visited via the Internet browser (browser). The placement of cookies helps the website recognize the device on a next visit and is sometimes necessary for proper functioning. We use the term cookies in this policy for all files that collect information in this way.",
        },
        section2: {
          title: 'How we use cookies',
          p1: 'The cookies used on this website by AXtrail® are as follows:',
          p2: '1. Strictly necessary cookies and functionality cookies:',
          p3: "- To enable or optimize the functionalities offered by the website and improve the user's browsing experience.",
          p4: '2. Analytical cookies:',
          p5: '- Are installed by tools managed by third parties (Google Analytics, etc.) to monitor the quality of the user experience when browsing this website.',
          p6: '3. Third party cookies:',
          p7: '- Facebook, YouTube, Vimeo, OpenStreetMap and other tools managed by third parties may install cookies to share content on social networks or to generate query statistics. The presence, number and status of these cookies may depend on how you use the relevant platforms before or during a visit to this website. It is useful to check the cookie management policies of these social networks on their websites. We sometimes use third-party web services to display content on our website, such as maps or videos. As with social network sharing buttons, we cannot prevent these websites or external domains from collecting information about the use of this embedded content.',
        },
        section3: {
          title: 'What are cookies used for?',
          p1: 'Cookies are used to determine the usefulness, interest and number of uses of the websites, to allow faster and more efficient navigation and to avoid the repeated entry of the same information.',
        },
        section4: {
          title: 'Do you want to refuse a cookie through your browser?',
          p1: 'You can decide at any time whether you want to disable all or part of the cookies. The browser can also be configured to inform you of the cookies present on the screen and ask you to accept them or not (on a case-by-case basis or completely). However, we inform you that disabling all cookies will prevent you from using the website under normal conditions, except for the basic functions.',
        },
      },
    },
    registrations: 'Registrations',
    details: 'Details',
    theRaces: 'The Races',
    contacts: 'Contacts',
    legalPages: 'Legal Pages',
    privacyPolicy: 'Privacy Policy',
    cookiesPolicy: 'Cookies Policy',
  },
};

export default translationData;
