import classNames from 'classnames';

const GlobalWrapper = ({ fullScreen, className, children }) => {
  return (
    <div
      className={classNames(
        fullScreen
          ? 'w-full'
          : 'max-w-[350px] sm:max-w-[700px] lg:max-w-[1100px] xl:max-w-[1200px]',
        'mx-auto',
        className
      )}
    >
      {children}
    </div>
  );
};

export default GlobalWrapper;
