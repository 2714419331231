import { useMemo } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import classNames from 'classnames';
import { useCookies } from 'react-cookie';

import HomeComponent from './Routes/Home';
import RaceComponent from './Routes/Race';
import CookiesPolicy from './Routes/CookiesPolicy';
import PrivacyPolicy from './Routes/PrivacyPolicy';

import CookiesPopoverComponent from './Components/CookiesPopover/CookiesPopover';
import Header from './Components/Header';
import FooterComponent from './Components/Footer';

function App() {
  const [cookies, setCookie] = useCookies(['cookieAccepted']);

  const showCookiesPopover = useMemo(
    () => !cookies.cookieAccepted || cookies.cookieAccepted === 'false',
    [cookies]
  );

  const handleCookieAccepted = () => {
    setCookie('cookieAccepted', true);
  };

  return (
    <BrowserRouter>
      <div className={classNames('max-w-[100vw] overflow-x-hidden')}>
        <Header />
        <Routes>
          <Route path="/" element={<HomeComponent />} key="homepage" />
          <Route path="/race/:raceId" element={<RaceComponent />} key="race" />
          <Route
            path="/cookies-policy"
            element={<CookiesPolicy />}
            key="cookies-policy"
          />
          <Route
            path="/privacy-policy"
            element={<PrivacyPolicy />}
            key="privacy-policy"
          />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <FooterComponent />
        {showCookiesPopover && (
          <CookiesPopoverComponent
            handleCookieAccepted={handleCookieAccepted}
          />
        )}
      </div>
    </BrowserRouter>
  );
}

export default App;
