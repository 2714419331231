import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Translator from '../../../../i18n/Translator';

const RaceItem = ({ raceTitle, raceHighlight, distance, climb, raceId }) => (
  <div
    key={raceTitle}
    className={classNames(
      'flex flex-col items-center gap-3',
      'border rounded-md p-10'
    )}
  >
    <div>
      <img src={raceHighlight.src} alt={raceHighlight.alt} />
    </div>
    <h3 className="text-2xl">{raceTitle}</h3>
    <div className={classNames('flex gap-5')}>
      <span className="text-xl">{distance}</span>
      <span className="text-xl">{climb}</span>
    </div>
    <div className={classNames('flex gap-2')}>
      <a
        href="https://stopandgo.net/events/axtrail2024"
        target="_blank"
        rel="noreferrer"
      >
        <button
          className={classNames(
            'bg-[rgb(44,44,44)] rounded-md text-white px-5 py-2'
          )}
        >
          <Translator path="registrations" />
        </button>
      </a>
      <Link to={`/race/${raceId}`}>
        <button
          className={classNames(
            'border border-[rgb(44,44,44)] rounded-md text-[rgb(44,44,44)] px-5 py-2'
          )}
        >
          <Translator path="details" />
        </button>
      </Link>
    </div>
  </div>
);

export default RaceItem;
