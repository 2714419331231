export const translationData = {
  translations: {
    cookies: {
      bar: {
        title: 'Cookies',
        details:
          'Este site utiliza cookies e outras tecnologias de rastreamento para ajudar na sua navegação e na nossa capacidade de fornecer feedback, analisar o uso do nosso site e ajudar a fornecer informações promocionais sobre nossos serviços e produtos.',
        acceptButton: 'Aceito',
      },
      page: {
        pageTitle: 'Política de Cookies',
        section1: {
          title: 'O que são cookies',
          p1: 'Os cookies são pequenos ficheiros de texto que um site, quando visitado, coloca no computador do utilizador ou no seu dispositivo móvel, através do navegador de internet (browser). A colocação de cookies ajuda o site a reconhecer o dispositivo numa próxima visita sendo por vezes necessários para um bom funcionamento. Usamos o termo cookies nesta política para referir todos os ficheiros que recolhem informações desta forma.',
        },
        section2: {
          title: 'Como utilizamos os cookies',
          p1: 'Os cookies utilizados neste website da AXtrail® são os seguintes:',
          p2: '1. Cookies estritamente necessários e de Funcionalidade:',
          p3: '- Para viabilizar ou optimizar as funcionalidades oferecidas pelo website e melhorar a experiência de navegação do utilizador.',
          p4: '2. Cookies Analíticos:',
          p5: '- Instalados por ferramentas geridas por terceiros (Google Analytics, etc.) para acompanhar a qualidade da experiência do utilizador na navegação neste website.',
          p6: '3. Cookies de terceiros:',
          p7: '- Facebook, YouTube, Vimeo, OpenStreetMap e outros instrumentos geridos por terceiros podem instalar cookies para fins de partilha de conteúdos nas redes sociais ou de elaboração de estatísticas de consulta. A presença, o número e o estatuto destes cookies podem depender da forma de utilização das plataformas em questão antes ou durante uma visita a este website. É útil verificar qual a política de gestão dos cookies dessas redes sociais nos respetivos websites. Por vezes utilizamos serviços web de terceiros para mostrar conteúdos dentro do nosso website, por exemplo, para mostrar mapas ou vídeo. À semelhança dos botões de partilha para redes sociais, não podemos evitar que estes websites ou domínios externos recolham informações acerca da utilização feita destes conteúdos incorporados.',
        },
        section3: {
          title: 'Para que servem os cookies',
          p1: 'Você pode a qualquer momento optar por desativar todos ou parte dos cookies. O navegador também pode ser configurado para o notificar sobre cookies disponibilizados no ecrâ e pedir para aceitá-los ou não (caso a caso ou na íntegra). No entanto, lembramos que a desativação de todos os cookies impedirá que você use o website em condições normais, exceto para funções básicas.',
        },
        section4: {
          title: 'Recusar um cookie através do seu navegador',
          p1: 'Você pode a qualquer momento optar por desativar todos ou parte dos cookies. O navegador também pode ser configurado para o notificar sobre cookies disponibilizados no ecrâ e pedir para aceitá-los ou não (caso a caso ou na íntegra). No entanto, lembramos que a desativação de todos os cookies impedirá que você use o website em condições normais, exceto para funções básicas.',
        },
      },
    },
    registrations: 'Inscrições',
    details: 'Detalhes',
    theRaces: 'As Provas',
    contacts: 'Contactos',
    legalPages: 'Páginas Legais',
    privacyPolicy: 'Política de Privacidade',
    cookiesPolicy: 'Política de Cookies',
  },
};

export default translationData;
